import React from 'react'
import "./Purchase.css"
import profileImg from "../../../assets/img/magazine-mockup.png";

const Purchase = () => {
  return (
    <section className="userProfile-purchase">
        <div className="container-fluid">
            <div className="row">
                <div className="purchase-card col-12 ">
                

                  <div className="purchase-details ">

                  <div className=" top-section">
                    <h5>Order#12515</h5>                  
                    <h6>4 April 2025</h6>
                   

                  </div>
 
                  <div className="bottom-section">
                    <p>Total order: <span>$52</span></p>
                  </div>

                  </div>
 

<div className=" col-12 d-flex gap-5  ">
                    <div className="magazine-card  col-3  ">
                    
                  <div className="magazine-name">
                      <p> April 2025 Edition</p>
                      
                      </div>
                     

                  <div className="image">
                      <img src={profileImg} className="img-fluid" alt=""/>
                    </div>
                    

                    <p className="veiw-magazine">Veiw Magazine</p>
                    </div>
                   
                   
                  <div className="magazine-card col-3  ">
                    
                    <div className="magazine-name">
                        <p> April 2025 Edition</p>
                        
                        </div>
                       
  
                    <div className="image">
                        <img src={profileImg} className="img-fluid" alt=""/>
                      </div>
  
                      <p className="veiw-magazine">Veiw Magazine</p>
                      </div>

                      <div className="magazine-card col-3  ">
                    
                    <div className="magazine-name">
                        <p> April 2025 Edition</p>
                        
                        </div>
                       
  
                    <div className="image">
                        <img src={profileImg} className="img-fluid" alt=""/>
                      </div>
  
                      <p className="veiw-magazine">Veiw Magazine</p>
                      </div>

                      <div className="magazine-card  col-3 ">
                        <div className="two">
                          <p>+2</p>
                          </div>
                       
                     
                      </div>
                   
                    </div>
                </div>
 

            </div>

        </div>

    </section>
  )
}

export default Purchase
