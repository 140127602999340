import React from "react";
import "./MyOrder.css";
import UserProfileHeader from "../UserProfileHeader/UserProfileHeader";
import profileImg from "../../../assets/img/magazine-mockup.png";

const MyOrder = () => {
  return (
    <section className="userProfile-myorder">
      <div className="container-fluid">
        <div className="row">
          {/* <UserProfileHeader/> */}

          <div className="myorder col-12">

            <div className="myorder-card">

              <div className="top-section">

                <div className="input-div">
                  <h5>Type Of Status</h5>
                  <p>Digital</p>
                </div>

                <div className="input-div">
                  <h5>Status</h5>
                  <p>Active</p>
                </div>

              </div>

              <div className="bottom-section">

                <div className="input-div">
                  <h5> Subscribtion Validity</h5>
                  <p> March 2026</p>
                </div>

                <div className="input-div">
                  <h5>Renewal Date</h5>
                  <p> July 2025</p>
                </div>

              </div>
 
            </div>



            <div className="image-card">
            
                    
                    <div className="magazine-name">
                        <p> April 2025 Edition</p>
                        
                        </div>
                       
  
                    <div className="image">
                        <img src={profileImg} className="img-fluid" alt=""/>
                      </div>
  
                      <p className="veiw-magazine">Veiw Magazine</p>
                      </div>




                
            </div>

         
          
        </div>
      </div>
    </section>
  );
};

export default MyOrder;
