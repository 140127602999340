import React, { useEffect } from 'react'
import './PaymentSuccessfull.css'
import { FaCircleCheck } from "react-icons/fa6";
import { NavLink } from 'react-bootstrap';
import MainNavbar from '../MainNavbar/MainNavbar';
import paymentBanner from '../../assets/img/paymentBanner.webp';
import { Link } from 'react-router-dom';


const PaymentSuccessfull = () => {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])
  return (
    <seciton className="payment-succesfull">
        <div className='container-fluid'>
            <div className='row'>
            <div className='image '>
                <img src={paymentBanner}  className='img-fluid' alt='' />
                <h1>Thank You !</h1>
            </div>
            <div className='check-btn'>
            <FaCircleCheck />
            <p>Payment  Done Successfully</p>
            </div>
            <p>
            Thank you for your purchase. Your transaction has been completed successfully.
            <br></br> 📩 A confirmation email with your order details has been sent to your registered email.<br></br> If you have any queries, feel free to contact our support team.</p>

            <div className='bottom-btn'>
               <Link to='/'> <button className='theme-btn'>Go back to Home</button></Link>
               <Link to='/my-profile'>  <button className='theme-btn'>View Profile</button></Link>
            </div>
        </div>
        </div>
    </seciton>
  )
}

export default PaymentSuccessfull
