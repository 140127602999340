import "./EditionsAboutSection.css";
import dummyImage from "../../../../assets/img/contact-news.png";
export default function EditionsAboutSection({ data }) {


    return (
        <section className="section-spacing  editions-about-section">
            <div className="container-fluid">
                {data?.map((item, id) => {
                    return (
                        <div className="row justify-content-between" key={id}>

                            {/* <div className="col-md-7  order-2 order-md-1">
                                <h2 className="main-heading">
                                    {item?.fld_heading}
                                </h2>
                                <p>
                                    {item?.fld_short_desc}
                                </p>

                            </div> */}


                            <div className="col-md-12 bottom-img order-1 order-md-2  text-end   mt-5 mt-lg-0">
                                {/* <img src={item?.fld_image} className="img-fluid" alt={item?.fld_alt} /> */}
                                  <img src={dummyImage} className="img-fluid" alt='' />

                            </div>
                        </div>
                    )
                })}

            </div>
        </section>
    )
}