import { MdExpandCircleDown } from "react-icons/md";
import DigitalIcon from "../../../assets/img/icons/E-Learning-1.png";
import DigitalIconWhite from "../../../assets/img/icons/E-Learning.png";
import PrintIcon from "../../../assets/img/icons/Comics Magazine-1.png";
import PrintIconWhite from "../../../assets/img/icons/Comics Magazine.png"
import AdvertisingIcon from "../../../assets/img/icons/Commercial-1.png";
import AdvertisingIconWhite from "../../../assets/img/icons/Commercial.png";
import SponsorshipIcon from "../../../assets/img/icons/Management-1.png";
import SponsorshipIconWhite from "../../../assets/img/icons/Management.png";
import ContributorIcon from "../../../assets/img/icons/Hand With Pen-1.png";
import ContributorIconWhite from "../../../assets/img/icons/Hand With Pen.png";
 

import "./SubscriptionsSection.css";
import ServiceContactForm from "../ServiceContactForm/ServiceContactForm";
import { useContext, useEffect, useRef, useState } from "react";
import { store } from "../../../Helpers/Store/Store";
import GetApiCall from "../../../Helpers/Api/GetApi";
import Payments from "../../Payments/Payments";
import { useNavigate } from "react-router-dom";
export default function SubscriptionsSection() {
const scrollref = useRef(null)
let navigate = useNavigate()
const [showSubscription,setShowSubscription ] =useState(true);
const [showAdvertising,setShowAdvertising]=useState(true);
const [showSponsorship,setShowSponsorship]=useState(false);
const [showContributor,setShowContributor]=useState(false);
const [subscription, setSubscription]= useState([])

const {scrollTodiv,setScrollTodiv} = useContext(store)
const advertisingHandleClick=()=>{
    setShowAdvertising(true);
    setShowSponsorship(false);
    setShowContributor(false);
}

const sponsorshipHandleClick=()=>{
    setShowSponsorship(true);
    setShowAdvertising(false);
    setShowContributor(false);
}
const contributorHandleClick=()=>{
    setShowContributor(true);
    setShowAdvertising(false);
    setShowSponsorship(false);


}

  useEffect(() => {
    if (scrollTodiv) {
      const targetDiv = document.getElementById(scrollTodiv);
      if (targetDiv) {
        const rect = targetDiv.getBoundingClientRect();
        const targetScrollTop = rect.top + window.pageYOffset;

        const scrollStep = (targetScrollTop - window.pageYOffset) / 20; // Divide the scroll into 20 steps
        let currentScrollTop = window.pageYOffset;

        const smoothScroll = () => {
          if (
            (scrollStep > 0 && currentScrollTop < targetScrollTop) ||
            (scrollStep < 0 && currentScrollTop > targetScrollTop)
          ) {
            currentScrollTop += scrollStep;
            window.scrollTo({
              top: currentScrollTop,
              behavior: "auto", // Disable smooth scrolling for this operation
            });
            requestAnimationFrame(smoothScroll);
          } else {
            window.scrollTo({
              top: targetScrollTop,
              behavior: "smooth", // Re-enable smooth scrolling for final step
            });
            setScrollTodiv(null);
}
        };

        smoothScroll();
      }
    }
    // eslint-disable-next-line
  }, [scrollTodiv]);
    useEffect(()=>{
        GetApiCall.getRequest("GetSubscriptions").then((results) => {
        results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
                setSubscription(obj.data);
            } else {
            }
        });
        });
    },[])
    return (
        <section className="section-spacing subscriptions-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 top-section" id="subscribe">
                        <div className="main-heading">
                            <h2>A. <b><i>DSA</i></b> <span>subscriptions</span></h2>
                        </div>
                        <p> <b> <i> DSA </i></b> offers both online and print magazines. Stay ahead with in-depth insights on global defence and security. Subscribe now for exclusive access to expert analysis and the latest updates!</p>
                        <div className="d-flex gap-4 gap-lg-5 mt-4 mt-lg-5">
                            <div className={`sub-btn ${showSubscription?"active":""}`} onClick={()=>{
                                setScrollTodiv("digital")
                                setShowSubscription(true);
                            }}>
                            
                                <img className="" src={showSubscription?DigitalIconWhite:DigitalIcon}  alt="" /> Digital
                            </div>
                            <div className={`sub-btn ${showSubscription?"":"active"}`} onClick={()=>{
                                setShowSubscription(false);
                                setScrollTodiv("print")}}>
                                <img src={ showSubscription?PrintIcon:PrintIconWhite } alt="" /> print
                            </div>
                        </div>
                        <div className="divider">
                            <MdExpandCircleDown />
                        </div>
                    </div>

{/* onClick of digital show the    digital subscription other wise show the print Subscription */}

                 {/* {showSubscription?   */}
                 <div className="col-12 my-5" id="sec-1" ref={scrollref}>
                        <div className="main-heading mt-4" id="digital">
                            <h2>1. Digital <span>subscription</span></h2>
                        </div>
                        <div className="my-4 d-flex flex-column flex-lg-row">
                            {/* Current Annual Edition Card */}
                            {subscription?.map((data)=>{
                                return(
                                <div className="subscription-card mb-5 mb-lg-0 mx-2">
                                <h5 className="subscription-card-title">{data?.fld_type}</h5>
                                <h3 className="subscription-card-price">{data?.fld_currency} {data?.fld_price} <span className="subscription-card-year">{data?.fld_heading}</span></h3>
                                <p className="mb-3">Subscriptions</p>
                                <p dangerouslySetInnerHTML={{ __html: data?.fld_title }}/>
                                {/* <p dangerouslySetInnerHTML={{ __html: data?.fld_long_desc }}/> */}

                               { data?.fld_type == 'CURRENT ANNUAL Edition'  &&
                               <ul className="features-list">
                                      <li>The<span><i> DSA</i></span>   Website (Articles + Newsletter)</li>
                                    <li>The<span><i>DSA</i></span>Digital Magazine- 12 Editions</li>
                                    <li>No third-party advertising</li>  
                                    <li>Subscription Validity 1 Year (from the month of subscription)</li>
                                </ul> }

                                {data?.fld_type == 'EDITIONS' && 
                                 <ul className="features-list">
                                    <li>The <span><i>DSA</i></span> Website (Articles + Newsletter)</li>
                                    <li> Choose your Edition</li>
                                    <li>No Third-Part Advertising</li>
                                    <li>Subscription Validity 1 Year (from the month of subscription)</li>
                                </ul>}



                               {data?.fld_type == 'CURRENT ANNUAL Edition' && 
                                // <Payments usdamount={15}/>
                               <button className="btn proceed-btn" onClick={()=>{
                                }}>Proceed</button>
                                }
                                {data?.fld_type == 'EDITIONS' && 
                               <button className="btn proceed-btn" onClick={()=>{
                                // navigate("/choose-editions")
                                }}>Proceed</button>
                                }
                            </div>
                                )
                            })}
                            

                            {/* Editions Card */}
                            {/* <div className="subscription-card mx-2">
                                <h5 className="subscription-card-title">EDITIONS</h5>
                                <h3 className="subscription-card-price">USD 1.25 <span className="subscription-card-year">Per Edition</span></h3>
                                <p className="mb-3">Subscriptions</p>
                                <p className="subscription-card-text">Access Both The <i>DSA's</i> Website And Digital Magazine.</p>
                                <ul className="features-list">
                                    <li>The <i> <span>DSA </span></i> Website (Articles + Newsletter)</li>
                                    <li> choose your Edition</li>
                                    <li>No Third-Part Advertising</li>
                                    <li>Subscription Validity 1 Year (from the month of subscription)</li>
                                </ul>
                                <button className="btn proceed-btn">Proceed</button>
                            </div> */}
                        </div>
                        <div className="divider">

                        </div>
                    </div>
                    
                    
                    
                    
                    {/* : */}




                        {/* // Print Subscription */}
                    <div className="col-12 mb-lg-5 section-2" id="sec-2" ref={scrollref}>
                        <div className="main-heading mt-4" id="print">
                            <h2>2. print <span>subscription</span></h2>
                        </div>
                        <div className="subscription-container">
                            {/* For Local Subscriptions */}
                            <div className="subscription-section">
                                <h4>For <span> INDIA</span></h4>
                                <p className="mb-2">Subscriptions</p>
                                <table className="subscription-table">
                                    <thead>
                                        <tr>
                                            <th>Tenure</th>
                                            <th>Cover Page</th>
                                            <th>Discount</th>
                                            <th>Discounted Price</th>
                                            <th>Shipping Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1 Year</td>
                                            <td>RS. 1800</td>
                                            <td>20%</td>
                                            <td>RS. 1400</td>
                                            <td>Rs 640</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* For SAARC Countries */}
                            <div className="subscription-section">
                                <h4>For SAARC Countries </h4>
                                <p className="mb-2">Subscriptions</p>
                                <table className="subscription-table">
                                    <thead>
                                        <tr>
                                            <th>Tenure</th>
                                            <th>Cover Page</th>
                                            <th>Discount</th>
                                            <th>Discounted Price</th>
                                            <th>Shipping Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1 Year</td>
                                            <td>US $ 300</td>
                                            <td>10%</td>
                                            <td>US $ 270</td>
                                            <td>US $ 500</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* For The Rest of the World */}
                            <div className="subscription-section">
                                <h4>For The Rest Of The World</h4>
                                <p className="mb-2">Subscriptions</p>
                                <table className="subscription-table">
                                    <thead>
                                        <tr>
                                            <th>Tenure</th>
                                            <th>Cover Page</th>
                                            <th>Discount</th>
                                            <th>Discounted Price</th>
                                            <th>Shipping Charges</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1 Year</td>
                                            <td>US $ 360</td>
                                            <td>10%</td>
                                            <td>US $ 324</td>
                                            <td>US $ 600</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                                
                  {/* } */}




                </div>

{/* first contact from not show in digital section but show in print section  */}

              {showSubscription?"":
                 <div className="py-5 my-5 contact-form-bg">
                    <div className="col-lg-8 mx-auto ">
                        <ServiceContactForm />
                    </div>
                </div> 
}




            {/* B. Associate with DSA Section*/}
                <div className="row my-5">
                    <div className="col-12 mt-5 top-section" id="associate">
                        <div className="main-heading">
                            <h2>B. Associate <span>with <i>DSA</i></span></h2>
                        </div>
                        <p className="bottom-p">Explore opportunities to partner with us, whether as a contributor, advertiser, or collaborator. Let's work together to advance the defense and security dialogue.</p>
                        <div className="d-flex flex-column flex-md-row gap-4 gap-lg-5 mt-5">
                            {/* <div className="sub-btn">
                                Partnership Opportunities
                            </div> */}
                            <div className={`sub-btn ${showAdvertising?"active":""}`} onClick={advertisingHandleClick}>
                                <img  src={showAdvertising?AdvertisingIconWhite:AdvertisingIcon} alt=""/>
                                Advertising 
                            </div>

                            <div className={`sub-btn ${showSponsorship?"active":""}`}onClick={sponsorshipHandleClick}>
                            <img  src={ showSponsorship?SponsorshipIconWhite:SponsorshipIcon} alt=""/>
                                Sponsorship
                            </div>
                            
                            <div className={`sub-btn ${showContributor?"active":""}`} onClick={contributorHandleClick}>
                            <img  src={showContributor?ContributorIconWhite:ContributorIcon} alt=""/>
                                Contributor
                            </div>

                        </div>
                        <div className="divider">
                            <MdExpandCircleDown />
                        </div>
                    </div>



                    <div className="col-12 my-5">

                       {showAdvertising ? 

                        <div className="d-flex mt-5">
                            <div className="no">
                                1.
                            </div>
                            <div>
                                <div className="main-heading">
                                    <h2>
                                        Advertising with DSA
                                    </h2>
                                </div>
                                <p className="bottom-p"> Thank you for your interest to associate with DSA to advertise your product/s,
                                    technologies and your brand on the DSA platform. We asuure that
                                     your association with us will be beneficial and you will attain a good return on your investment in  DSA.
                                </p>
                            </div>
                        </div>
                        :""}




                        {showSponsorship?


                        <div className="d-flex mt-5">
                            <div className="no">
                                2.
                            </div>
                            <div>                                
                                <div className="main-heading">
                                    <h2>
                                        Sponsorship with DSA
                                    </h2>
                                </div>
                                <p className="bottom-p">  Thank you for your interest to associating with DSA to sponsor our various 
                                    events related to defence, security and world affaris. We offer tailor-made  sponsorship
                                    packages to fit your marketing goals depending on the event/s We assure that your association as a sponsor to DSA events will
                                    bring fruitful results to you.

                                </p>
                            </div>
                        </div>
                        :""}


                         {showContributor?


                        <div className="d-flex mt-5">
                            <div className="no">
                                3.
                            </div>
                            <div>                                
                                <div className="main-heading">
                                    <h2>
                                        Contributor  with DSA
                                    </h2>
                                </div>
                                <p className="bottom-p">
                                    Welcome on-borad, Thanks!<br>
                                    </br>
                                    We welcome orginal, unpublished and research-based writings pertaining to defence,
                                    security and world affaris. Let your throughts be recognised through our platform. Join our mission
                                    to disseminate knowlegde and awareness on the critical subject of defence, security and world affaris.

                                </p>
                            </div>
                        </div>
                        :""}
 
                       

                    </div>
                </div>
                          {/* : */}
                    {/* <div className="col-12 my-5"> */}
                        {/* <div className="d-flex mt-5">
                            <div className="no">
                                1.
                            </div>
                            <div>
                                <div className="main-heading">
                                    <h2>
                                        Partnership <span>opportunities</span>
                                    </h2>
                                </div>
                                <p className="bottom-p">Collaborate with <i>DSA</i> to amplify your brand's and products reach in the defense and security sector. Partner with us to engage with a dedicated audience and enhance your impact.</p>
                            </div>
                        </div>
                        <div className="d-flex mt-5">
                            <div className="no">
                                2.
                            </div>
                            <div>
                                <div className="main-heading">
                                    <h2>
                                        Advertising <span>packages</span>
                                    </h2>
                                </div>
                                <p className="bottom-p">Maximize your visibility through our tailored advertising packages, designed to connect your brand with key decision-makers and professionals in the defense industry.</p>
                            </div>
                        </div> */}


                        {/* <div className="d-flex mt-5">
                            <div className="no">
                                1.
                            </div>
                            <div>

                                
                                <div className="main-heading">
                                    <h2>
                                        Sponsorship  with DSA
                                    </h2>
                                </div>
                                <p className="bottom-p">Support <i>DSA's</i> initiatives through sponsorship and gain exclusive recognition across our platforms, aligning your brand with excellence in defense journalism.</p>
                            </div>
                        </div> */}
                    {/* </div> */}
                {/* </div>  */}
{/*  
                      {/* }  */}
                <div className="py-lg-5 mt-lg-5 bottom-contact-form">
                    <div className="col-lg-8 contact-form-bg mx-auto">
                        <ServiceContactForm />
                    </div>
                </div>
            </div>
        </section>
    )
}