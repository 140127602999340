import React, { useState } from 'react'
import "./UserInfo.css"
import UserProfileHeader from "../UserProfileHeader/UserProfileHeader"

const UserInfo = () => {
    const [firstName,setFirstName]=useState();
    const [lastName,setLastName]=useState();
    const[email,setEmail]=useState();
    const[phoneNo,setPhoneNo]=useState();
  return (
    <section className="user-profile-user-info">
        <div className="conatiner-fluid">
            <div className="row">
                <UserProfileHeader/>
                <form className="input-form">

                <div className="top-section">

                  <div className="input-div">
                    <label>First Name</label>
                    <input type="text" placeholder=""/>
                  </div>
                  <div className="input-div" >
                    <label>Last Name</label>
                    <input type="text" placeholder=""/>
                  </div>


                </div>
 
                <div className="bottom-section">

                  <div className="input-div">
                    <label> Email</label>
                    <input type="email" placeholder=""/>
                  </div>

                  <div className="input-div" >
                    <label>Phone Number</label>
                    <input type="text" placeholder=""/>
                  </div>


                </div>

                <button className="theme-btn">Save Changes</button>



                </form>


                  

            </div>

        </div>


  
    </section>
  )
}

export default UserInfo
