import React, { useEffect, useState } from 'react';
import { Card, Input, Modal, notification, Select, message, Upload } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import PostApiCall from '../../Helpers/Api/PostApi';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);   
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});
export default function Articles() {
  const randomnum = parseInt(Math.random()* 1000000)
    const [articleAllData, setArticleAllData] = useState([]);
    const [isOpenModal,setIsOpenModal] = useState(false);
    const [setPreviewImageDesktop] = useState(null);
    const [urlData, setUrlData] = useState([]);
    const [setFileListDesktop] = useState([]);
    const { setValue,  handleSubmit,control, reset, formState: { errors } } = useForm();
    const onSubmit = data => UpdateNews(data)

    const GetMagazineArticles = () => {
        PostApiCall.postRequest({whereClause:""},"GetMagazineArticles").then((results)=> {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setArticleAllData(obj.data); 
          }else {
            notification.error({
              message: `Notification error`,
              description: obj.data,
            });
            }
          })
        })
      }
    useEffect(()=>{
        GetMagazineArticles();
    },[])

    const handleCloseModal = () =>{
        setIsOpenModal(false)
    }
    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'},
            {'list': 'bullet'}, 
            {'indent': '-1'}, 
            {'indent': '+1'}],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      }
      const onSubmitForm = (data) => {
        onSubmit(data);
      };
      const UpdateNews = (data) => {
        console.log(data)
        PostApiCall.postRequest(
          {
            id :data?.id || null, 
            type :data?.region, 
            heading:data?.heading, 
            title :data?.title, 
            region : 'India', 
            published_by :data?.published_by, 
            read_time :data?.read_time, 
            short_desc : data?.short_desc, 
            long_desc : data?.long_desc, 
            thumbnail :data?.image[0]?.url, 
            image :data?.image[0]?.url, 
            profile_image: data?.profileimage[0]?.url,
            alt :"", 
            category : data?.category, 
            created_by: 1, 
            updated_by : 1,
            status: data?.status == true ? 'Active' : "InActive",
            profile_desc: data?.profile_desc,
            image_src: data?.image_src
          },"UpdateArticles").then((results)=> {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              GetMagazineArticles();
              reset({
                id:null,
                image: [],
                category: "",
                region: "",
                heading: "",
                title: "",
                short_desc: "",
                long_desc: "",
                published_by: "",
                read_time: "",
                status: false,
                image_src:"",
                profile_desc:""
            })
            notification.success({message: `Saved Sucessfully`})
            handleCloseModal()
          }else {
            notification.error({
              message: `Notification error`,
              description: obj.data,
            });
            }
          })
        })
      }
      const onaction = async (dat) => {
        const formData = new FormData();
        formData.append("filename", randomnum + dat.file.name);
        formData.append("foldername", "articles");
        formData.append("file", dat.file);
        await axios
          .post("https://dsalert.org/dsalert-api/AddImage", formData, {
            headers: {
              Schema: "",
            },
          })
          .then((res) => {
            setValue("image", [{ uid: "-1", name: "", url: res?.data?.Message }]);
            setUrlData([...urlData, res?.data?.Message]);
          })
          .catch((err) => {
            if (err.response) {
              console.log("Error Response:", err.response.data);
              console.log("Error Status:", err.response.status);
              console.log("Error Headers:", err.response.headers);
            } else if (err.request) {
              console.log("Error Request:", err.request);
            } else {
              console.log("Error Message:", err.message);
            }
            console.log("Error Config:", err.config);
        });
    };
    const onactionprofile = async (dat) => {
        const formData = new FormData();
        formData.append("filename", randomnum + dat.file.name);
        formData.append("foldername", "article-profile");
        formData.append("file", dat.file);
        await axios
          .post("https://dsalert.org/dsalert-api/AddImage", formData, {
            headers: {
              Schema: "",
            },
          })
          .then((res) => {
            setValue("profileimage", [{ uid: "-1", name: "", url: res?.data?.Message }]);
          })
          .catch((err) => {
            if (err.response) {
              console.log("Error Response:", err.response.data);
              console.log("Error Status:", err.response.status);
              console.log("Error Headers:", err.response.headers);
            } else if (err.request) {
              console.log("Error Request:", err.request);
            } else {
              console.log("Error Message:", err.message);
            }
            console.log("Error Config:", err.config);
        });
    };
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    };
  
    const handleChangeDesktop = async ({ file, fileList }) => {
      const updatedFileList = [...fileList];
      const index = updatedFileList.findIndex((item) => item.uid === file.uid);
      if (index !== -1) {
        updatedFileList[index] = { ...file, status: "done" };
        setFileListDesktop(updatedFileList);
      }
      if (file.status === "done") {
        updatedFileList[index] = { ...file, status: "done" };
        setFileListDesktop(updatedFileList);
      } else if (file.status === "error") {
        updatedFileList[index] = { ...file, status: "error" };
        setFileListDesktop(updatedFileList);
      } else if (file.status === "removed") {
        setFileListDesktop(updatedFileList.filter((item) => item.uid !== file.uid));
      }
      if (fileList.length > 0) {
          const reader = new FileReader();
          reader.onload = (e) => setPreviewImageDesktop(e.target.result);
          reader.readAsDataURL(fileList[0].originFileObj);
      } else {
          setPreviewImageDesktop(null);
      }
  };
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
        message.error('You can only upload image files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
    }
    return isImage && isLt2M;
};
    return (
        <Container fluid>
            <div className='w-100 mb-4 d-flex justify-content-between'>
                <h1>Articles</h1>
            <button className='theme-btn' onClick={()=>{
                setIsOpenModal(true)
                reset({
                  id:null,
                  image: [],
                  category: "",
                  region: "",
                  heading: "",
                  title: "",
                  short_desc: "",
                  long_desc: "",
                  published_by: "",
                  read_time: "",
                  status: false,
                  profileimage:[],
                  image_src:"",
                  profile_desc:""
              });
            }}>Add New Article</button>
            </div>
            <Row>
            {articleAllData?.sort((a,b)=>b.fld_id - a.fld_id)?.map((dat)=>{
                return(
                <Col lg={3}>
                        <Card
                            className='mb-4'
                            hoverable
                            cover={<img alt="" src={dat.fld_image} />}
                            >
                                <p>{dat?.fld_type } - {dat?.fld_category}</p>
                                <h6>{dat?.fld_heading}</h6>
                                <button className='theme-btn' onClick={()=>{
                                        setIsOpenModal(true)
                                        setValue("profileimage", [{ uid: "-1", name: "existing-image.jpg", url: dat.fld_profile_image }]);
                                        setValue("image", [{ uid: "-1", name: "existing-image.jpg", url: dat.fld_image }]);
                                        reset({
                                          id:dat?.fld_id,
                                          image: [{ uid: "-1", name: "existing-image.jpg", url: dat.fld_image }],
                                          profileimage: [{ uid: "-1", name: "existing-image.jpg", url: dat.fld_profile_image }],
                                          category: dat.fld_category || "",
                                          region: dat.fld_type || "",
                                          heading: dat.fld_heading || "",
                                          title: dat.fld_title || "",
                                          short_desc: dat.fld_short_desc || "",
                                          long_desc: dat.fld_long_desc || "",
                                          published_by: dat.fld_published_by || "",
                                          read_time: dat.fld_read_time || "",
                                          image_src:dat?.fld_image_src || "",
                                          profile_desc :dat?.fld_profile_desc || "",
                                          status: dat.fld_status == "Active" ? true : false || false,
                                      });
                                    }}>Edit</button>
                                {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
                        </Card>
                        </Col>
                )
            })}
            </Row>
            <Modal
        // title="Basic Modal"
        open={isOpenModal}
        onCancel={handleCloseModal}
        footer={false}
        centered
        width={1000}
      >
        <h2>Add / Update Article</h2>
   <form className="p-4 border rounded bg-light shadow-sm" onSubmit={handleSubmit(onSubmitForm)}>
      <Row gutter={[16, 16]}>
        <Col lg={4} className="mb-3">
          <div className="form-group">
            <label>Article Image</label>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <Upload
                  customRequest={onaction}
                  fileList={field.value}
                  listType="picture-card"
                  onPreview={handlePreview}
                  onChange={handleChangeDesktop}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  // onChange={(info) => field.onChange(info.fileList)}
                >
                  {field.value.length === 0 && (
                  <div className="upload-placeholder btn">
                    <UploadOutlined /> Click to Upload Image
                  </div>
                  )}
                  {field?.value[0]?.url &&
                  <img className="upload-placeholder btn w-100 h-100" src={field?.value[0]?.url}/>
                  }
                </Upload>
              )}
            />
          </div>
        </Col>
        <Col lg={4} className="mb-3">
          <div className="form-group">
            <label>Profile Image</label>
            <Controller
              name="profileimage"
              control={control}
              render={({ field }) => (
                <Upload
                  customRequest={onactionprofile}
                  fileList={field.value}
                  listType="picture-card"
                  onPreview={handlePreview}
                //   onChange={handleChangeDesktop}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  // onChange={(info) => field.onChange(info.fileList)}
                >
                  {field.value.length === 0 && (
                  <div className="upload-placeholder btn">
                    <UploadOutlined /> Click to Upload profile Image
                  </div>
                  )}
                  {field?.value[0]?.url &&
                  <img className="upload-placeholder btn w-100 h-100" src={field?.value[0]?.url}/>
                  }
                </Upload>
              )}
            />
          </div>
        </Col>

        <Col lg={4} className="mb-3">
        <label>Image Source</label>
          <Controller
            name="image_src"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <Input {...field} 
            className="form-control" 
            placeholder="Image Source" />}
          />
           {errors.image_src && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Category Select */}
        <Col lg={4} className="mb-3 catselect">
          <div className="form-group">
            <label>Category</label>
            <Controller
              name="category"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="form-control"
                  placeholder="Select Category"
                  options={[
                    { value: "Contributors", label: "Contributors" },
                    { value: "Defence", label: "Defence" },
                    { value: "Security", label: "Security" },
                    { value: "Worldwide Affairs", label: "Worldwide Affairs" },
                    { value: "Border Conflicts", label: "Border Conflicts" },
                    { value: "Emerging tech", label: "Emerging tech" },
                  ]}
                />
              )}
            />
            {errors.category && <span className="text-danger">This field is required</span>}
          </div>
        </Col>

        {/* Heading */}
        <Col lg={8} className="mb-3">
        <label>Heading</label>
          <Controller
            name="heading"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} 
            className="form-control"
            placeholder="Heading" />}
          />
           {errors.heading && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Short Description */}
        <Col lg={12} className="mb-3">
        <label>Short Description</label>
          <Controller
            name="short_desc"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} 
            className="form-control"
            placeholder="Short Description" />}
          />
           {errors.short_desc && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Long Description */}
        <Col lg={12} className="mb-3">
        <label>Long Description</label>
          <Controller
            name="long_desc"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ReactQuill
                theme="snow"
                modules={modules}
                value={field.value}
                onChange={field.onChange}
                className="bg-white"
              />
            )}
          />
           {errors.long_desc && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Published By */}
        <Col lg={6} className="mb-3">
        <label>Published By</label>
          <Controller
            name="published_by"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <Input {...field} 
            className="form-control" 
            placeholder="Published By" />}
          />
           {errors.published_by && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Read Time */}
        <Col lg={6} className="mb-3">
        <label>Read Time</label>
          <Controller
            name="read_time"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <Input {...field} 
            className="form-control" 
            placeholder="Read Time (e.g., 5 min)" />}
          />
           {errors.read_time && <span className="text-danger">This field is required</span>}
        </Col>

        <Col lg={6} className="mb-3">
        <label>Profile Description</label>
          <Controller
            name="profile_desc"
            // rules={{ required: true }}
            control={control}
            render={({ field }) => <Input {...field} 
            className="form-control" 
            placeholder="Profile Description" />}
          />
           {/* {errors.profile_desc && <span className="text-danger">This field is required</span>} */}
        </Col>

        {/* Show on Website */}
        <Col lg={6} className="mb-3">
          <Controller
            name="status"
            // rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <div className="form-check form-switch">
                <input 
                className="form-check-input" 
                type="checkbox" 
                checked={field.value} 
                onChange={field.onChange} />
                <label className="form-check-label">Show on Website</label>
              </div>
            )}
          />
           {errors.status && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Submit Button */}
        <Col lg={12} className="mt-4">
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary px-4 py-2" type="submit">Save</button>
          </div>
        </Col>
      </Row>
    </form>
      </Modal>
  </Container>
    );
}

