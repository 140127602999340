import axios from "axios";
import React, { useContext } from "react";
import "../ServicesPage/SubscriptionsSection/SubscriptionsSection.css"
import Notiflix from "notiflix";
import { store } from "../../Helpers/Store/Store";
import { useNavigate } from "react-router-dom";
export default function Payments ({usdamount}){
    let navigate = useNavigate();
    const {setShow} = useContext(store)
    const handlePayment = async (amount) => {
        // var login = localStorage.getItem("CustomerData");
        // var logindetails = JSON.parse(login);

        try {
            // create order
            const response = await axios.post(
                "http://localhost:3308/dsalert-api/Payment",
                { amount }
            );

            const { order } = response.data;


            // Razorpay payment options
            var options = {
                key: process.env.REACT_PAYMENT_KEY || "rzp_test_uiGkJ8ANswl5Wa", // Razorpay Key ID
                amount,
                currency: "USD",
                name: process.env.REACT_NAME || "",
                description: "",
                // image: logo,
                order_id: order.id, // Razorpay order ID
                handler: async (response) => {
                    const body = { ...response ,editions: JSON.parse(localStorage.getItem("itemsInCart")),customer_id:1,payment_method:"RazorPay"};

                    Notiflix.Loading.dots("Your transaction is under process..");

                    try {
                        const validateRes = await axios.post(
                            "http://localhost:3308/dsalert-api/order",
                            body,
                            { headers: { "Content-Type": "application/json" } }
                        );
                        if (validateRes.data.message === 'Order and payment recorded successfully') {
                            const Mailer = await axios.post(
                                "http://localhost:3308/dsalert-api/ordermailer",
                                {name:"Manmeet",
                                email:"manmeetsehgal493@gmail.com" 
                                },
                                { headers: { "Content-Type": "application/json" } }
                            );
                            localStorage.removeItem("itemsInCart")
                            navigate('/payment-successful')
                            Notiflix.Loading.remove();
                        } else {
                            navigate('/payment-failed')
                            console.log("Payment error");
                            Notiflix.Loading.remove();
                        }
                    } catch (error) {
                        console.error("Error:", error);
                        // Notiflix.Loading.remove();
                    }
                },
                // prefill: {
                //     name: localStorage.getItem("CustomerData") == null
                //         ? null
                //         : logindetails[0].fld_name,
                //     email: localStorage.getItem("CustomerData") == null
                //         ? null
                //         : logindetails[0].fld_email,
                // },
                // notes: {
                //     cartid: cartID,
                //     itemsdetaiils: JSON.stringify(cartItemsDetail),
                //     paymentTrxNum: paymentTrxNum,
                // },
                theme: {
                    color: "#800000",
                },
            };

            // open rzp modal
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => {
                const rzp = new window.Razorpay(options);
                rzp.open();
                // Notiflix.Loading.remove();
            };
            document.head.appendChild(script);
        } catch (error) {
            console.error("Error in processing payment:", error);
        }
    };
return(
    <div className="subscriptions-section">
    <button className="btn proceed-btn w-100" onClick={()=>{
        sessionStorage.getItem("access") ? 
        handlePayment(usdamount) : setShow(true)}}>Pay Now ( USD {usdamount} )</button>
    </div>

)
}