import "./AlertPage.css";
import NavigationSection from "../NavigationSection/NavigationSection";

import { useState, useEffect } from 'react';
import PostApiCall from "../../../Helpers/Api/PostApi";
import moment from "moment";

export default function AlertPage() {
    const currentYear = new Date().getFullYear();
    const startYear = 2024;
    const years = [];
    const [newsLetterAlertData, setNewsLetterAlertData] = useState([]);
    const [filteredAlerts,setFilteredAlerts] = useState([]);
    const [selectedFilter,setSelectedFilter] = useState("All")

    const getNewsLetterAlertData = () => {
        PostApiCall.postRequest({ whereClause: "" }, "GetNewsAlert").then((results) => {
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setNewsLetterAlertData(obj.data);
                } else {
                    // notification.error({
                    //   message: `Notification error`,
                    //   description: obj.data,
                    // });
                }
            })
        })
    }

    useEffect(() => {
        getNewsLetterAlertData();
        onFilter("");
    }, [])

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])
    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }
    const onFilter = (type,value) =>{
        let filtered = newsLetterAlertData.filter((item) => item.fld_status === "Active");
        if (type === "year") {
            setSelectedFilter(type)
            filtered = filtered.filter((item) => {
            const year = new Date(item.fld_createdon).getFullYear().toString();
            return year === value;
            });
        }

        if (type === "month") {
            setSelectedFilter(type)
            filtered = filtered.filter((item) => {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const month = monthNames[new Date(item.fld_createdon).getMonth()];
            return month === value;
            });
        }

        setFilteredAlerts(filtered);
        };
    return (
        <>
            <NavigationSection />
            <section className="section-spacing alert-page py-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="category-tab">
                                <p>
                                    Categories
                                </p>
                                <ul>
                                    <li className={selectedFilter === "year" ? 'Active' : ''}>
                                    <select onChange={(e)=>onFilter("year",e.target.value)}>
                                    <option value="year" disabled>
                                        Select Year
                                    </option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                        {year}
                                        </option>
                                    ))}
                                    </select>
                                    </li>
                                    <li className={selectedFilter === "month" ? 'Active' : ''}>
                                    <select onChange={(e)=>onFilter("month",e.target.value)}>
                                            <option selected disabled>Select Month</option>
                                            <option  value="Jan">Jan</option>
                                            <option value="Feb">Feb</option>
                                            <option  value="Mar">Mar</option>
                                            <option  value="Apr">Apr</option>
                                            <option  value="May">May</option>
                                            <option  value="Jun">Jun</option>
                                            <option  value="Jul">Jul</option>
                                            <option  value="Aug">Aug</option>
                                            <option  value="Sep">Sep</option>
                                            <option  value="Oct">Oct</option>
                                            <option  value="Nov">Nov</option>
                                            <option  value="Dec">Dec</option>
                                            
                                    </select>
                                    </li>
                                    <li className={selectedFilter === "All" ? 'Active' : ''}
                                    onClick={()=>{
                                        setFilteredAlerts(newsLetterAlertData.filter((item) => item.fld_status === "Active"))
                                        setSelectedFilter("All")
                                    }}>
                                        All
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {filteredAlerts.length > 0 ? filteredAlerts.sort((a,b)=>b.fld_id - a.fld_id)?.map((item, id) => {
                                return <div className="alert-news-card" key={id}>
                                    <div className="image">
                                        <img src={item?.fld_image} className="img-fluid" alt={item?.fld_alt} />
                                    </div>
                                    <div className="content">
                                        <div>
                                            <h3>
                                                {item?.fld_title}
                                            </h3>
                                            <p dangerouslySetInnerHTML={{ __html: item?.fld_long_desc }} />

                                        </div>
                                        <div className="d-flex justify-content-between mt-4">
                                            <span>
                                                Date -  {moment.utc(item?.fld_createdon).format('MMMM D, YYYY')  || "Not available"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }):"Alerts you are searching is not available"}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}