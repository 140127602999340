import React from 'react'
import "./Sidebar.css"
import SiderbarItems from "./SidebarItems.js"
import profileImg from "../../../assets/img/magazine-mockup.png";
 
import { Link } from "react-router-dom"

const Sidebar = () => {
  return (
    <section className="user-profile-sidebar">
    <div className="container-fluid">
        <div className="row">
        <div className="sidebar col-5 ">

          <div className="top-section" >
            <div className="image">
            <img src={profileImg} className="img-fluid" alt=""/>
            </div>
            <div>
            Hi,
            <p>Sara Tancredi</p>
            </div>
          </div>
            {/* <span className='item-center'>User Profile</span> */}
         <ul className="">
          <li className="">
            {SiderbarItems.map((items) => {
            return(
              <Link to={items.href} key={items.title}>
                <div className="content ">
                 <p className=" "> {items.icon}</p>
                  <span className="">
                    {items.title}
                  </span>
                  {/* <p className=""> */}
                  {/* {items.title}</p>  */}
 
                </div>

                
              </Link>
              
               
            )}
            
            )}
          </li>
 
        </ul>

        <div className="logout">
          <button className="theme-btn"> Log out</button>
        </div>
        
      
        </div>
        

    </div>
    </div>
    </section>
  )
}

export default Sidebar
