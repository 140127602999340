import React from 'react'
import "./UserProfileHeader.css"
import profileImg from "../../../assets/img/magazine-mockup.png";
import { MdOutlineModeEdit } from "react-icons/md";

const UserProfileHeader = () => {
  return (
     <section className="userProfile-userProfileHeader">
        <div className="container-fluid">
            <div className="row">
                <div className="profile ">
                    <div className="image">
                    <img src={profileImg} className="img-fluid" alt=""/>
                    <div className="edit">
                     <MdOutlineModeEdit  size={25}/>
 
                </div>

                    </div>
                    <div className="content">
                    <h4>Sara Tancredi</h4>
                    <p>New York, USA</p>
                    </div>


                </div>

            </div>

        </div>


     </section>
  )
}

export default UserProfileHeader
