import { FaRegUser } from "react-icons/fa";
import { MdFavoriteBorder } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import { BiSolidPurchaseTag } from "react-icons/bi";
const SiderbarItems = [
  {
    title: "User info",
    href: "/my-profile",
    icon: <FaRegUser size={20} />,
  },
  {
    title: "My Order",
    href: "/my-profile/myorder",
    icon: <FaShoppingCart size={20} />,
  },
  {
    title: "Purchase History",
    href: "/my-profile/purchase",
    icon: <BiSolidPurchaseTag size={20} />,
  },
];
export default SiderbarItems;
