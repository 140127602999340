import "./MagazinePageTopSection.css";
import LeftImg from "../../../assets/img/contact-mission.png";
import { Link } from "react-router-dom";

export default function MagazinePageTopSection({data}) {
    
    return (
        <section className="section-spacing  pb-0 magazine-page-top-section">
            <div className="container position-relative">
                <div className="row magazine-wrapper">
                    <div className="left-img">
                        <img src={LeftImg} className="img-fluid " alt="" />
                    </div>
                    <div className="col-lg-7 order-1 col-sm-8  mx-auto text-end text-lg-center center-banner-wrapper">
                        {/* <img src={data[0]?.fld_image} alt={data[0]?.fld_alt} className="img-fluid center-banner" /> */}
                         <img src={LeftImg} alt={data[0]?.fld_alt} className="img-fluid center-banner mx-auto" />
                    </div>
                    <div className="content-box order-2">
                        <div className="main-heading">
                            {/* heading format : check this */}

                            <h2>
                                <i>DSA</i> <span>magazine</span>
                            </h2>
                            </div>
                        <b>
                        Since October 2009,
                        </b>
                        <p>
                        Discover our monthly editions, each packed with a wealth of knowledge on the latest developments in defence and security. Our issues feature exclusive interviews, expert analyses, and thought-provoking articles that provide insight into the dynamic world of defence.
                            {/* {data[0]?.fld_short_desc} */}
                        </p>
                        {/* Note : points at the bottom of the page, it should be at the top */}
                        <Link to="/editions" className="theme-btn">View our Editions</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}