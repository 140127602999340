import React, { useEffect, useState } from 'react';
import { Card, Input, Modal, notification, Select, message, Upload } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import PostApiCall from '../../Helpers/Api/PostApi';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);   
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});
export default function Alerts() {
  const randomnum = parseInt(Math.random()* 1000000)
    const [newsLetterAllData, setNewsLetterAllData] = useState([]);
    const [isOpenModal,setIsOpenModal] = useState(false);
    const [setPreviewImageDesktop] = useState(null);
    const [urlData, setUrlData] = useState([]);
    const [setFileListDesktop] = useState([]);
    const { setValue,  handleSubmit,control, reset, formState: { errors } } = useForm();
    const onSubmit = data => UpdateNewsAlerts(data)

    const GetNewsAlert = () => {
        PostApiCall.postRequest({whereClause:""},"GetNewsAlert").then((results)=> {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              setNewsLetterAllData(obj.data); 
          }else {
            notification.error({
              message: `Notification error`,
              description: obj.data,
            });
            }
          })
        })
      }
    useEffect(()=>{
        GetNewsAlert();
    },[])

    const handleCloseModal = () =>{
        setIsOpenModal(false)
    }
    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'},
            {'list': 'bullet'}, 
            {'indent': '-1'}, 
            {'indent': '+1'}],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      }
      const onSubmitForm = (data) => {
        onSubmit(data);
      };
      const UpdateNewsAlerts = (data) => {
        PostApiCall.postRequest(
          {
            id :data?.id || null, 
            title :data?.title, 
            long_desc : data?.long_desc, 
            image :data?.image[0]?.url,
            created_by: 1, 
            updated_by : 1,
            status: data?.status == true ? 'Active' : "InActive",
          },"UpdateNewsAlerts").then((results)=> {
          results.json().then((obj) => {
            if (results.status === 200 || results.status === 201) {
              GetNewsAlert();
              handleCloseModal();
              reset({
                id:null,
                image: [],
                title: "",
                long_desc: "",
                status: false,
            })
          }else {
            notification.error({
              message: `Notification error`,
              description: obj.data,
            });
            }
          })
        })
      }
      const onaction = async (dat) => {
        const formData = new FormData();
        formData.append("filename", randomnum + dat.file.name);
        formData.append("foldername", "news");
        formData.append("file", dat.file);
        await axios
          .post("https://dsalert.org/dsalert-api/AddImage", formData, {
            headers: {
              Schema: "",
            },
          })
          .then((res) => {
            setValue("image", [{ uid: "-1", name: "", url: res?.data?.Message }]);
            setUrlData([...urlData, res?.data?.Message]);
          })
          .catch((err) => {
            if (err.response) {
              console.log("Error Response:", err.response.data);
              console.log("Error Status:", err.response.status);
              console.log("Error Headers:", err.response.headers);
            } else if (err.request) {
              console.log("Error Request:", err.request);
            } else {
              console.log("Error Message:", err.message);
            }
            console.log("Error Config:", err.config);
        });
    };
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      // setPreviewImage(file.url || file.preview);
      // setPreviewOpen(true);
    };
  
    
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
        message.error('You can only upload image files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
    }
    return isImage && isLt2M;
};
    return (
        <Container fluid>
            <div className='w-100 mb-4 d-flex justify-content-between'>
                <h1>Newsletter Alerts</h1>
            <button className='theme-btn' onClick={()=>{
                setIsOpenModal(true)
                reset({
                  id:null,
                  image: [],
                  title: "",
                  long_desc: "",
                  status: false,
              });
            }}>Add New Alert</button>
            </div>
            <Row>
            {newsLetterAllData.sort((a,b)=>b.fld_id - a.fld_id)?.map((dat)=>{
                return(
                <Col lg={3}>
                        <Card
                            className='mb-4'
                            hoverable
                            cover={<img alt="" src={dat.fld_image} />}
                            >
                                <p>{moment.utc(dat?.fld_createdon).format('MMMM D, YYYY')}</p>
                                <h6>{dat?.fld_title}</h6>
                                <button className='theme-btn' onClick={()=>{
                                        setIsOpenModal(true)
                                        setValue("image", [{ uid: "-1", name: "existing-image.jpg", url: dat.fld_image }]);
                                        reset({
                                          id:dat?.fld_id,
                                          image: [{ uid: "-1", name: "existing-image.jpg", url: dat.fld_image }],
                                          title: dat.fld_title || "",
                                          long_desc: dat.fld_long_desc || "",
                                          status: dat.fld_status == "Active" ? true : false || false,
                                      });
                                    }}>Edit</button>
                        </Card>
                        </Col>
                )})}
            </Row>
            <Modal
        // title="Basic Modal"
        open={isOpenModal}
        onCancel={handleCloseModal}
        footer={false}
        centered
        width={1000}
      >
        <h2>Add / Update News</h2>
   <form className="p-4 border rounded bg-light shadow-sm" onSubmit={handleSubmit(onSubmitForm)}>
      <Row gutter={[16, 16]}>
        <Col lg={4} className="mb-3">
          <div className="form-group">
            {/* <label>Upload Image</label> */}
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <Upload
                  customRequest={onaction}
                  fileList={field.value}
                  listType="picture-card"
                  onPreview={handlePreview}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  // onChange={(info) => field.onChange(info.fileList)}
                >
                  {field.value.length === 0 && (
                  <div className="upload-placeholder btn">
                    <UploadOutlined /> Click to Upload Image
                  </div>
                  )}
                  {field?.value[0]?.url &&
                  <img alt='' className="upload-placeholder btn w-100 h-100" src={field?.value[0]?.url}/>
                  }
                </Upload>
              )}
            />
          </div>
        </Col>
        {/* Title */}
        <Col lg={8} className="mb-3">
          <Controller
            name="title"
            control={control}
            render={({ field }) => <Input {...field} 
            className="form-control" 
            placeholder="Title" />}
          />
           {errors.title && <span className="text-danger">This field is required</span>}
        </Col>
        {/* Long Description */}
        <Col lg={12} className="mb-3">
          <Controller
            name="long_desc"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ReactQuill
                theme="snow"
                modules={modules}
                value={field.value}
                onChange={field.onChange}
                className="bg-white"
              />
            )}
          />
           {errors.long_desc && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Show on Website */}
        <Col lg={6} className="mb-3">
          <Controller
            name="status"
            // rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <div className="form-check form-switch">
                <input 
                className="form-check-input" 
                type="checkbox" 
                checked={field.value} 
                onChange={field.onChange} />
                <label className="form-check-label">Show on Website</label>
              </div>
            )}
          />
           {errors.status && <span className="text-danger">This field is required</span>}
        </Col>

        {/* Submit Button */}
        <Col lg={12} className="mt-4">
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary px-4 py-2" type="submit">Save</button>
          </div>
        </Col>
      </Row>
    </form>
      </Modal>
  </Container>
    );
}

